<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('studies.studentEmails') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<p class="text-body-2 text-justify" style="max-width: 90%">
						{{ $t('studies.studentEmailsHelp1') }}
					</p>
					<p class="text-body-2 text-justify" style="max-width: 90%">
						{{ $t('studies.studentEmailsHelp2') }}
					</p>
					<p class="text-body-2 text-justify" style="max-width: 90%">
						{{ $t('studies.studentEmailsHelp3') }}
					</p>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-list v-cloak subheader flat color="transparent">
						<v-subheader>{{ $t('settings.numberOfStudentEmails') }}: {{ studentEmails.length }}/{{ maxStudentEmails }}</v-subheader>
						<v-list-item v-for="(studentEmail, index) in studentEmails" :key="index">
							<v-list-item-avatar>
								<v-icon>mdi-school</v-icon>
							</v-list-item-avatar>

							<v-list-item-content>
								<v-list-item-title>{{ studentEmail.email }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>
								<v-dialog v-model="dialog" persistent max-width="350">
									<template v-slot:activator="{ on }">
										<v-btn icon color="error" v-on="on">
											<v-icon>mdi-close-circle</v-icon>
										</v-btn>
									</template>
									<v-card rounded="xl">
										<v-card-title>
											<span class="headline">{{ $t('courses.areYouSure') }}</span>
										</v-card-title>
										<v-card-text>{{ $t('studies.removeStudentEmail') }}</v-card-text>
										<v-card-actions>
											<v-spacer />
											<v-btn color="blue darken-1" text rounded @click="dialog = false">
												{{ $t('offers.cancel') }}
											</v-btn>
											<v-btn
												color="error darken-1"
												text
												rounded
												:loading="loadingRemoval"
												:disabled="loadingRemoval"
												@click="callRemoveStudentEmail(studentEmail)"
											>
												{{ $t('settings.remove') }}
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'SettingsStudentEmails',
	props: {
		maxStudentEmails: {
			type: Number,
			default: 0,
		},
		studentEmails: {
			type: Array,
			default() {
				return []
			},
		},
	},
	data() {
		return {
			valid: false,
			dialog: false,
			loadingRemoval: false,
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
		}),
	},
	methods: {
		callRemoveStudentEmail(student) {
			this.loadingRemoval = true
			this.removeStudentEmail({ student }).then(() => {
				this.dialog = false
				this.loadingRemoval = false
			})
		},
		...mapActions('studies', ['removeStudentEmail']),
	},
}
</script>
